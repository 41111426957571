import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getUserHourlyWages() {
    return axiosIns.get('/v2/employee/wage_management/user_hourly_wages')
  },
  createUserHourlyWage({
    userId,
    hourlyWageId,
    hourlyWageCategoryId = null,
  }) {
    return axiosIns.post(
      '/v2/employee/wage_management/user_hourly_wages',
      snakecaseKeys({
        userId,
        hourlyWageId,
        hourlyWageCategoryId,
      }),
    )
  },
  updateUserHourlyWage(id, params = {}) {
    const {
      userId,
      hourlyWageId,
      hourlyWageCategoryId = null,
    } = params

    return axiosIns.put(
      `/v2/employee/wage_management/user_hourly_wages/${id}`,
      snakecaseKeys({
        userId,
        hourlyWageId,
        hourlyWageCategoryId,
      }),
    )
  },
  destroyUserHourlyWage(id) {
    return axiosIns.delete(`/v2/employee/wage_management/user_hourly_wages/${id}`)
  },
}
