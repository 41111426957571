<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="userHourlyWages"
      :header-props="{ sortByText: 'ソート' }"
      :footer-props="{ itemsPerPageText: '行/ページ:' }"
      :loading="loading"
      group-by="meta.hourlyWageCategoryName"
      show-group-by
      no-data-text="データがありません"
    >
      <template #top>
        <v-toolbar
          flat
          color="transparent"
        >
          <v-spacer />
          <v-btn
            color="primary"
            :ripple="false"
            small
            @click="editDialog = true"
          >
            新規作成
          </v-btn>
        </v-toolbar>
      </template>

      <template #[`item.meta.userRole`]="{ item }">
        {{ rolesI18n[item.meta.userRole] }}
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn
          icon
          :ripple="false"
        >
          <v-icon
            small
            @click="edit(item)"
            v-text="icons.mdiPencilOutline"
          />
        </v-btn>
        <v-dialog max-width="400">
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              :ripple="false"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                v-text="icons.mdiTrashCanOutline"
              />
            </v-btn>
          </template>
          <template #default="dialog">
            <v-card>
              <v-card-title>削除の確認</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    このデータを削除すると、給与計算システムなどの他の機能に影響を及ぼす可能性があります。
                  </v-col>
                  <v-col cols="12">
                    また、一度削除したデータを元に戻すことはできません。
                  </v-col>
                  <v-col cols="12">
                    ※保存済みの集計データ等には影響はありません。
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  small
                  @click="dialog.value = false"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  color="error"
                  small
                  @click="
                    dialog.value = false;
                    $emit('destroy', item.attributes.id);
                  "
                >
                  同意の上で削除を実行
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
    </v-data-table>

    <v-dialog
      v-model="editDialog"
      max-width="400"
      @click:outside="resetParams"
    >
      <template #default="dialog">
        <v-card>
          <v-card-title>
            {{ dialogTitle }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  :value="selectedUserName"
                  required
                  label="対象ユーザー"
                  :items="users"
                  item-text="attributes.name"
                  dense
                  :autofocus="isNew"
                  :disabled="!isNew"
                  @change="onUserSelect"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="params.hourlyWageId"
                  required
                  :items="hourlyWages"
                  item-value="attributes.id"
                  item-text="meta.itemText"
                  label="時間帯時給"
                  placeholder="選択してください"
                  persistent-placeholder
                  no-data-text="選択可能なデータがありません"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="params.hourlyWageCategoryId"
                  :items="hourlyWageCategories"
                  item-value="attributes.id"
                  item-text="attributes.name"
                  label="時給分類"
                  placeholder="指定なし"
                  persistent-placeholder
                  no-data-text="選択可能なデータがありません"
                  clearable
                  :autofocus="!isNew"
                >
                </v-select>
              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              small
              @click="close(dialog)"
            >
              キャンセル
            </v-btn>
            <v-btn
              color="primary"
              :disabled="isInvalid"
              small
              @click="save(dialog)"
            >
              {{ saveBtnText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref,
  toRefs,
  reactive,
  computed,
} from '@vue/composition-api'
import { mdiTrashCanOutline, mdiPencilOutline } from '@mdi/js'

export default {
  props: {
    userHourlyWages: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Array,
      default: () => [],
    },
    hourlyWageCategories: {
      type: Array,
      default: () => [],
    },
    hourlyWages: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const editDialog = ref(false)

    const { users } = toRefs(props)

    const headers = [
      { text: '対象ユーザー', value: 'meta.userName' },
      { text: 'ロール', value: 'meta.userRole' },
      { text: '時給分類', value: 'meta.hourlyWageCategoryName' },
      { text: '時間帯別時給', value: 'meta.hourlyWageName' },
      { text: '', value: 'actions', sortable: false },
    ]

    const params = reactive({
      id: null,
      userId: null,
      hourlyWageCategoryId: null,
      hourlyWageId: null,
    })

    const selectedUserName = computed(() => {
      const { userId } = params
      if (userId) {
        return users.value.find(user => user.attributes.id === userId)?.attributes?.name
      }

      return ''
    })

    const isNew = computed(() => params.id === null)

    const isInvalid = computed(() => {
      // TODO
      return false
    })

    const dialogTitle = computed(() => (isNew.value ? '新規作成' : '編集'))
    const saveBtnText = computed(() => (isNew.value ? '新規作成' : '更新'))

    const onUserSelect = item => {
      if (item?.attributes?.id) {
        params.userId = item.attributes.id
      }
    }

    const edit = item => {
      params.id = item.attributes.id
      params.userId = item.attributes.userId
      params.hourlyWageCategoryId = item.attributes.hourlyWageCategoryId
      params.hourlyWageId = item.attributes.hourlyWageId
      editDialog.value = true
    }

    const resetParams = () => {
      params.id = null
      params.userId = null
      params.hourlyWageCategoryId = null
      params.hourlyWageId = null
    }

    const close = dialog => {
      // eslint-disable-next-line no-param-reassign
      dialog.value = false
      resetParams()
    }

    const save = dialog => {
      if (isInvalid.value) return

      const paramsToEmit = { ...params }
      emit((isNew.value ? 'create' : 'update'), paramsToEmit)

      close(dialog)
    }

    return {
      editDialog,
      headers,
      selectedUserName,
      isNew,
      isInvalid,
      dialogTitle,
      saveBtnText,
      params,
      rolesI18n: { cast: 'キャスト', waiter: 'スタッフ', alliance: 'アライアンス' },

      onUserSelect,
      resetParams,
      close,
      edit,
      save,

      icons: {
        mdiTrashCanOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
