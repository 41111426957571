var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.hourlyWageCategories,"header-props":{ sortByText: 'ソート' },"footer-props":{ itemsPerPageText: '行/ページ:' },"loading":_vm.loading,"show-expand":"","no-data-text":"データがありません"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","ripple":false,"small":""},on:{"click":function($event){_vm.editDialog = true}}},[_vm._v(" 新規作成 ")])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"ma-2"},_vm._l((_vm.userHourlyWages(item.attributes.id)),function(userHourlyWage,index){return _c('v-col',{key:("user-hourly-wage-" + (item.id) + "-" + index),attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"},domProps:{"textContent":_vm._s(userHourlyWage.meta.userName)}}),_c('v-col',{staticClass:"flex-grow-1",domProps:{"textContent":_vm._s(userHourlyWage.meta.hourlyWageName)}})],1)],1)}),1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","ripple":false}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.icons.mdiPencilOutline)},on:{"click":function($event){return _vm.edit(item)}}})],1),_c('v-dialog',{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","ripple":false}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.icons.mdiTrashCanOutline)}})],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',[_vm._v("削除の確認")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" このデータを削除すると、給与計算システムなどの他の機能に影響を及ぼす可能性があります。 ")]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" また、一度削除したデータを元に戻すことはできません。 ")]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" ※保存済みの集計データ等には影響はありません。 ")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" キャンセル ")]),_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){dialog.value = false;
                  _vm.$emit('destroy', item.attributes.id);}}},[_vm._v(" 同意の上で削除を実行 ")])],1)],1)]}}],null,true)})]}}],null,true),model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}}),_c('v-dialog',{attrs:{"max-width":"400"},on:{"click:outside":_vm.resetParams},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"名前","type":"text","autofocus":""},model:{value:(_vm.params.name),callback:function ($$v) {_vm.$set(_vm.params, "name", $$v)},expression:"params.name"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.close(dialog)}}},[_vm._v(" キャンセル ")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.isInvalid,"small":""},on:{"click":function($event){return _vm.save(dialog)}}},[_vm._v(" "+_vm._s(_vm.saveBtnText)+" ")])],1)],1)]}}]),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }