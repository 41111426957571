<template>
  <v-container>
    <div class="d-flex flex-between">
      <h2>時給設定</h2>
      <v-spacer />
      <v-switch
        v-model="classicMode"
        label="Classic"
        hide-details
      />
    </div>

    <ClassicHourlyWagesPage
      v-if="classicMode"
      class="mt-5"
    />

    <template v-else>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        class="elevation-0"
      >
        <v-tab>ユーザー時給</v-tab>
        <v-tab>時間別時給</v-tab>
        <v-tab>時給分類</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-if="isLoading">
          <v-skeleton-loader type="table" />
        </v-tab-item>

        <template v-else>
          <v-tab-item>
            <UserHourlyWages
              :user-hourly-wages="userHourlyWages"
              :users="users"
              :hourly-wage-categories="hourlyWageCategories"
              :hourly-wages="hourlyWages"
              :loading="loadingStatuses.userHourlyWages"
              @update="updateUserHourlyWage"
              @create="createUserHourlyWage"
              @destroy="destroyUserHourlyWage"
            />
          </v-tab-item>

          <v-tab-item>
            <HourlyWages
              :hourly-wages="hourlyWages"
              :loading="loadingStatuses.hourlyWages"
              @update="updateHourlyWage"
              @create="createHourlyWage"
              @destroy="destroyHourlyWage"
            />
          </v-tab-item>

          <v-tab-item>
            <HourlyWageCategories
              :hourly-wage-categories="hourlyWageCategories"
              :loading="loadingStatuses.hourlyWageCategories"
              @update="updateHourlyWageCategory"
              @create="createHourlyWageCategory"
              @destroy="destroyHourlyWageCategory"
            />
          </v-tab-item>
        </template>
      </v-tabs-items>
    </template>
  </v-container>
</template>

<script>
import {
  ref,
  onMounted,
  reactive,
  getCurrentInstance,
} from '@vue/composition-api'
import UserApi from '@/api/v2/employee/wage-management/User'
import UserHourlyWageApi from '@/api/v2/employee/wage-management/UserHourlyWage'
import HourlyWageApi from '@/api/v2/employee/wage-management/HourlyWage'
import HourlyWageCategoryApi from '@/api/v2/employee/wage-management/HourlyWageCategory'
import HourlyWageCategories from './HourlyWageCategories.vue'
import HourlyWages from './HourlyWages.vue'
import UserHourlyWages from './UserHourlyWages.vue'
import useCompInit from '@/views/composable/useCompInit'
import ClassicHourlyWagesPage from '@/views/admin/wage/HourlyWages.vue'

export default {
  components: {
    HourlyWageCategories,
    HourlyWages,
    UserHourlyWages,
    ClassicHourlyWagesPage,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const classicMode = ref(true)
    const tab = ref(0)
    const users = ref([])
    const userHourlyWages = ref([])
    const hourlyWageCategories = ref([])
    const hourlyWages = ref([])
    const loadingStatuses = reactive({
      userHourlyWages: false,
      hourlyWageCategories: false,
      hourlyWages: false,
    })

    const { initWith, isLoading } = useCompInit()

    const createUserHourlyWage = async params => {
      loadingStatuses.userHourlyWages = true
      try {
        const res = await UserHourlyWageApi.createUserHourlyWage(params)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          userHourlyWages.value.push(data)
          vm.$toast.success('作成しました')
        }
      } catch (error) {
        error.response?.data?.message?.forEach(msg => {
          vm.$toast.error(msg)
        })
      } finally {
        loadingStatuses.userHourlyWages = false
      }
    }
    const updateUserHourlyWage = async (params = {}) => {
      loadingStatuses.userHourlyWages = true
      try {
        const { id } = params
        const res = await UserHourlyWageApi.updateUserHourlyWage(id, params)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          const idx = userHourlyWages.value.findIndex(t => t.attributes.id === data.attributes.id)
          if (idx >= 0) {
            userHourlyWages.value.splice(idx, 1, data)
          } else {
            userHourlyWages.value.push(data)
          }
          vm.$toast.success('更新しました')
        }
      } catch (error) {
        error.response?.data?.message?.forEach(msg => {
          vm.$toast.error(msg)
        })
      } finally {
        loadingStatuses.userHourlyWages = false
      }
    }
    const destroyUserHourlyWage = async id => {
      loadingStatuses.userHourlyWages = true
      try {
        const res = await UserHourlyWageApi.destroyUserHourlyWage(id)
        if (res.data.status === 'success') {
          const idx = userHourlyWages.value.findIndex(t => t.attributes.id === id)
          userHourlyWages.value.splice(idx, 1)
          vm.$toast.success('削除しました')
        }
      } catch (error) {
        error.response?.data?.message?.forEach(msg => {
          vm.$toast.error(msg)
        })
      } finally {
        loadingStatuses.userHourlyWages = false
      }
    }

    const createHourlyWageCategory = async params => {
      loadingStatuses.hourlyWageCategories = true
      try {
        const res = await HourlyWageCategoryApi.createHourlyWageCategory(params.name)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          hourlyWageCategories.value.push(data)
          vm.$toast.success('作成しました')
        }
      } catch (error) {
        error.response?.data?.message?.forEach(msg => {
          vm.$toast.error(msg)
        })
      } finally {
        loadingStatuses.hourlyWageCategories = false
      }
    }
    const updateHourlyWageCategory = async (params = {}) => {
      loadingStatuses.hourlyWageCategories = true
      try {
        const { id, name } = params
        const res = await HourlyWageCategoryApi.updateHourlyWageCategory(id, name)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          const idx = hourlyWageCategories.value.findIndex(t => t.attributes.id === data.attributes.id)
          if (idx >= 0) {
            hourlyWageCategories.value.splice(idx, 1, data)
          } else {
            hourlyWageCategories.value.push(data)
          }
          vm.$toast.success('更新しました')
        }
      } catch (error) {
        error.response?.data?.message?.forEach(msg => {
          vm.$toast.error(msg)
        })
      } finally {
        loadingStatuses.hourlyWageCategories = false
      }
    }
    const destroyHourlyWageCategory = async id => {
      loadingStatuses.hourlyWageCategories = true
      try {
        const res = await HourlyWageCategoryApi.destroyHourlyWageCategory(id)
        if (res.data.status === 'success') {
          const idx = hourlyWageCategories.value.findIndex(t => t.attributes.id === id)
          hourlyWageCategories.value.splice(idx, 1)
          vm.$toast.success('削除しました')
        }
        if (res.data.status === 'error') {
          vm.$toast.error('削除できません')
          vm.$toast.error(res.data.message.join('\n'))

          return
        }
      } catch (error) {
        error.response?.data?.message?.forEach(msg => {
          vm.$toast.error(msg)
        })
      } finally {
        loadingStatuses.hourlyWageCategories = false
      }
    }

    const createHourlyWage = async params => {
      loadingStatuses.hourlyWages = true
      try {
        const res = await HourlyWageApi.createHourlyWage(params)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          hourlyWages.value.push(data)
          vm.$toast.success('作成しました')
        }
      } catch (error) {
        error.response?.data?.message?.forEach(msg => {
          vm.$toast.error(msg)
        })
      } finally {
        loadingStatuses.hourlyWages = false
      }
    }
    const updateHourlyWage = async (params = {}) => {
      loadingStatuses.hourlyWages = true
      try {
        const { id } = params
        const res = await HourlyWageApi.updateHourlyWage(id, params)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          const idx = hourlyWages.value.findIndex(t => t.attributes.id === data.attributes.id)
          if (idx >= 0) {
            hourlyWages.value.splice(idx, 1, data)
          } else {
            hourlyWages.value.push(data)
          }
          vm.$toast.success('更新しました')
        }
      } catch (error) {
        error.response?.data?.message?.forEach(msg => {
          vm.$toast.error(msg)
        })
      } finally {
        loadingStatuses.hourlyWages = false
      }
    }
    const destroyHourlyWage = async id => {
      loadingStatuses.hourlyWages = true
      try {
        const res = await HourlyWageApi.destroyHourlyWage(id)
        if (res.data.status === 'success') {
          const idx = hourlyWages.value.findIndex(t => t.attributes.id === id)
          hourlyWages.value.splice(idx, 1)
          vm.$toast.success('削除しました')
        }
      } catch (error) {
        error.response?.data?.message?.forEach(msg => {
          vm.$toast.error(msg)
        })
      } finally {
        loadingStatuses.hourlyWages = false
      }
    }

    const getUsers = async () => {
      const res = await UserApi.getUsers()
      if (res.data.status === 'success') {
        const { data } = res.data.data
        users.value = data
      }
    }
    const getUserHourlyWages = async () => {
      const res = await UserHourlyWageApi.getUserHourlyWages()
      if (res.data.status === 'success') {
        const { data } = res.data.data
        userHourlyWages.value = data
      }
    }
    const getHourlyWages = async () => {
      const res = await HourlyWageApi.getHourlyWages()
      if (res.data.status === 'success') {
        const { data } = res.data.data
        hourlyWages.value = data
      }
    }
    const getHourlyWagesCategories = async () => {
      const res = await HourlyWageCategoryApi.getHourlyWageCategories()
      if (res.data.status === 'success') {
        const { data } = res.data.data
        hourlyWageCategories.value = data
      }
    }
    onMounted(() => {
      initWith([
        getUsers(),
        getUserHourlyWages(),
        getHourlyWages(),
        getHourlyWagesCategories(),
      ])
    })

    return {
      classicMode,
      tab,
      users,
      userHourlyWages,
      hourlyWageCategories,
      hourlyWages,
      isLoading,
      loadingStatuses,

      createUserHourlyWage,
      updateUserHourlyWage,
      destroyUserHourlyWage,
      createHourlyWageCategory,
      updateHourlyWageCategory,
      destroyHourlyWageCategory,
      createHourlyWage,
      updateHourlyWage,
      destroyHourlyWage,
    }
  },
}
</script>
