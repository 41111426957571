<template>
  <div>
    <v-data-table
      v-model:expanded="expanded"
      :headers="headers"
      :items="hourlyWages"
      :header-props="{ sortByText: 'ソート' }"
      :footer-props="{ itemsPerPageText: '行/ページ:' }"
      :loading="loading"
      show-expand
      no-data-text="データがありません"
    >
      <template #top>
        <v-toolbar
          flat
          color="transparent"
        >
          <v-spacer />
          <v-btn
            color="primary"
            :ripple="false"
            small
            @click="editDialog = true"
          >
            新規作成
          </v-btn>
        </v-toolbar>
      </template>

      <!-- eslint-disable-next-line vue/no-template-shadow -->
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="ma-2">
            <v-col
              v-for="(userHourlyWage, index) in userHourlyWages(item.attributes.id)"
              :key="`user-hourly-wage-${item.id}-${index}`"
              cols="12"
            >
              <v-row>
                <v-col
                  cols="3"
                  v-text="userHourlyWage.meta.userName"
                />
                <v-col
                  class="flex-grow-1"
                  v-text="userHourlyWage.meta.hourlyWageCategoryName"
                />
              </v-row>
            </v-col>
          </v-row>
        </td>
      </template>

      <template #[`item.attributes.endAt`]="{ item }">
        {{ item.attributes.endAt || '24:00' }}
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn
          icon
          :ripple="false"
        >
          <v-icon
            small
            @click="edit(item)"
            v-text="icons.mdiPencilOutline"
          />
        </v-btn>
        <v-dialog max-width="400">
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              :ripple="false"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                v-text="icons.mdiTrashCanOutline"
              />
            </v-btn>
          </template>
          <template #default="dialog">
            <v-card>
              <v-card-title>削除の確認</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    このデータを削除すると、給与計算システムなどの他の機能に影響を及ぼす可能性があります。
                  </v-col>
                  <v-col cols="12">
                    また、一度削除したデータを元に戻すことはできません。
                  </v-col>
                  <v-col cols="12">
                    ※保存済みの集計データ等には影響はありません。
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  small
                  @click="dialog.value = false"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  color="error"
                  small
                  @click="
                    dialog.value = false;
                    $emit('destroy', item.attributes.id);
                  "
                >
                  同意の上で削除を実行
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
    </v-data-table>

    <v-dialog
      v-model="editDialog"
      max-width="400"
      @click:outside="resetParams"
    >
      <template #default="dialog">
        <v-card>
          <v-card-title>
            {{ dialogTitle }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model.number="params.amount"
                  label="時給"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  prefix="¥"
                  autofocus
                />
              </v-col>
              <v-col cols="4">
                <v-switch
                  v-model="isAllDay"
                  label="終日"
                  hide-details
                />
              </v-col>
            </v-row>

            <v-row v-if="!isAllDay">
              <v-col cols="6">
                <v-text-field
                  v-model="params.timerangeStartAt"
                  label="開始"
                  type="time"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="params.timerangeEndAt"
                  label="終了"
                  type="time"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              small
              @click="close(dialog)"
            >
              キャンセル
            </v-btn>
            <v-btn
              color="primary"
              :disabled="isInvalid"
              small
              @click="save(dialog)"
            >
              {{ saveBtnText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  watch,
  toRefs,
} from '@vue/composition-api'
import { mdiTrashCanOutline, mdiPencilOutline } from '@mdi/js'

export default {
  props: {
    hourlyWages: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const { hourlyWages } = toRefs(props)

    const editDialog = ref(false)
    const expanded = ref(false)
    const isAllDay = ref(true)

    const userHourlyWages = computed(() => id => {
      return hourlyWages.value.find(hw => hw.attributes.id === id).attributes.userHourlyWages.data
    })

    const headers = [
      { text: '時給', value: 'attributes.amount' },
      { text: '開始', value: 'attributes.startAt' },
      { text: '終了', value: 'attributes.endAt' },
      {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'end',
      },
      {
        text: '依存関係',
        width: '80px',
        align: 'center',
        value: 'data-table-expand',
      },
    ]

    const params = reactive({
      id: null,
      amount: null,
      timerangeStartAt: '00:00',
      timerangeEndAt: null,
    })

    const isNew = computed(() => params.id === null)

    const isInvalid = computed(() => {
      // TODO
      return false
    })

    const dialogTitle = computed(() => (isNew.value ? '新規作成' : '編集'))
    const saveBtnText = computed(() => (isNew.value ? '新規作成' : '更新'))

    const edit = item => {
      params.id = item.attributes.id
      params.amount = item.attributes.amount
      params.timerangeStartAt = item.attributes.startAt
      params.timerangeEndAt = item.attributes.endAt

      isAllDay.value = !params.timerangeEndAt
      editDialog.value = true
    }

    const resetParams = () => {
      params.id = null
      params.amount = null
      params.timerangeStartAt = null
      params.timerangeEndAt = null
    }

    const close = dialog => {
      // eslint-disable-next-line no-param-reassign
      dialog.value = false
      resetParams()
    }

    const save = dialog => {
      if (isInvalid.value) return

      const paramsToEmit = { ...params }
      emit((isNew.value ? 'create' : 'update'), paramsToEmit)
      close(dialog)
    }

    watch(isAllDay, value => {
      if (value) {
        params.timerangeStartAt = '00:00'
        params.timerangeEndAt = null
      }
    })

    return {
      editDialog,
      expanded,
      headers,
      isNew,
      isInvalid,
      dialogTitle,
      params,
      isAllDay,
      saveBtnText,
      userHourlyWages,

      resetParams,
      close,
      edit,
      save,

      icons: {
        mdiTrashCanOutline,
        mdiPencilOutline,
        userHourlyWages,
      },
    }
  },
}
</script>
