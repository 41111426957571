import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getHourlyWageCategories() {
    return axiosIns.get('/v2/employee/wage_management/hourly_wage_categories')
  },
  createHourlyWageCategory(name) {
    return axiosIns.post(
      '/v2/employee/wage_management/hourly_wage_categories',
      snakecaseKeys({ name }),
    )
  },
  updateHourlyWageCategory(id, name) {
    return axiosIns.put(
      `/v2/employee/wage_management/hourly_wage_categories/${id}`,
      snakecaseKeys({ name }),
    )
  },
  destroyHourlyWageCategory(id) {
    return axiosIns.delete(`/v2/employee/wage_management/hourly_wage_categories/${id}`)
  },
}
