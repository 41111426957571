import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getHourlyWages() {
    return axiosIns.get('/v2/employee/wage_management/hourly_wages')
  },
  createHourlyWage({
    amount,
    timerangeStartAt,
    timerangeEndAt,
  }) {
    return axiosIns.post(
      '/v2/employee/wage_management/hourly_wages',
      snakecaseKeys({
        amount,
        timerangeStartAt,
        timerangeEndAt,
      }),
    )
  },
  updateHourlyWage(id, params = {}) {
    const {
      amount,
      timerangeStartAt,
      timerangeEndAt,
    } = params

    return axiosIns.put(
      `/v2/employee/wage_management/hourly_wages/${id}`,
      snakecaseKeys({
        amount,
        timerangeStartAt,
        timerangeEndAt,
      }),
    )
  },
  destroyHourlyWage(id) {
    return axiosIns.delete(`/v2/employee/wage_management/hourly_wages/${id}`)
  },
}
